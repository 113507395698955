import { AppRoutes } from 'routes';


export default function App() {
  return (
    <AppRoutes />
  )

}


