import Logo from '../../assets/image/package.png';

export function NavLogo() {
  return (
    <div>

      <img className="h-8 animate-none" src={Logo} alt="Logo" />


    </div>

  )
}